import Image from 'next/image';
import Link from 'next/link';
import Logo from 'public/rc-logo-footer.svg';

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <footer className="bg-white border-t-2 border-gray-200">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto overflow-hidden py-12 px-6 lg:px-8">
        <div className="block md:flex gap-8">
          <div className="md:border-r-2 md:border-gray-200 w-full md:w-1/4">
            <Image
              src={Logo}
              alt="Resource Central Logo"
              width={155}
              height={80}
              className="pb-8 mx-auto"
            />
          </div>
          <div className="pl-4 w-full md:w-3/4">
            <p className="text-base text-gray-400 italic mb-2">
              <strong>&copy; {year} Resource Central.</strong> Renew Our Schools
              is a program of Resource Central, an innovative nonprofit
              dedicated to helping people save water, reduce waste, and conserve
              energy. With over 40 years of experience, our programs make it
              easy to conserve natural resources.{' '}
              <Link
                href="/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
                className="text-ros-blue underline"
              >
                Terms of Use
              </Link>
              <Link
                href="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-ros-blue underline ml-2"
              >
                Privacy Policy
              </Link>
            </p>
            <p className="text-base text-gray-400 italic">
              Visit{' '}
              <Link
                href="https://resourcecentral.org"
                className="text-ros-blue underline"
                rel="noopener noreferrer"
                target="_blank"
              >
                resourcecentral.org
              </Link>{' '}
              for more information.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
