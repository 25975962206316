import { NextPageWithLayout } from 'types/app';
import { ReactElement, useEffect } from 'react';
import { useRouter } from 'next/router';
import DefaultLayout from 'components/layout/Default';

const Home: NextPageWithLayout = () => {
  const router = useRouter();
  useEffect(() => {
    router.push('/login');
  }, []);
  return <div></div>;
};

Home.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export default Home;
